@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500&display=swap");
@import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

@tailwind components;
@tailwind base;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl;
  }
  h2 {
    @apply text-3xl;
  }
  h3 {
    @apply text-2xl;
  }
}

#root,
#modal-root {
  --primary: #121cde;
  --background: #fafafa;
}

html,
body,
#root {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-1s2u09g-control {
  border-radius: 0.5rem !important;
}

#searchBar::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  right: 20px;
  border-radius: 50em;
  background: url("../images/groups/searchBarClearIcon.svg");
  background-size: contain;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

#video {
  width: 260px;
  height: 260px;
}
